import { FC } from 'react';
import { FormInstance } from 'antd';

import { SectionContainer } from './section-container/section-container';

import {
    FormSection,
    FormSectionLabel,
    GenderOptions,
    MainSectionOrder,
} from '~/constants/application-tab/certificate-form';
import { FormBlockLabel } from '~/constants/application-tab/general';
import { useGetRegionInfoQuery } from '~/rtk-queries/endpoints/user-info';
import { CountriesResponse, UserInfoResponse } from '~/rtk-queries/types/user-info';
import { CustomDataPicker } from '~/shared/form-components/custom-data-picker';
import { CustomMaskInput } from '~/shared/form-components/custom-mask-input';
import { CustomSelect } from '~/shared/form-components/custom-select';
import { optionCountry } from '~/utils/application-tab/get-country-data';
import { getFormSectionFields } from '~/utils/application-tab/get-form-section-fields';

type MainSectionProps = {
    form: FormInstance;
    data?: UserInfoResponse;
};

export const MainSection: FC<MainSectionProps> = ({ data, form }) => {
    const { data: countriesData = {} as CountriesResponse } = useGetRegionInfoQuery();

    if (!data) {
        return null;
    }

    return (
        <SectionContainer label={FormSectionLabel.main}>
            {getFormSectionFields(FormSection.main, data.userInfo.main, MainSectionOrder).map(
                ({ name, label, disabled, mask, rules }) => {
                    const currentValue = form.getFieldValue(name);

                    switch (label) {
                        case FormBlockLabel.citizenshipId:
                            return (
                                <CustomSelect
                                    key={name}
                                    options={optionCountry(countriesData.countries)}
                                    {...{ name, label, rules, mask, disabled }}
                                />
                            );

                        case FormBlockLabel.gender:
                            return (
                                <CustomSelect
                                    key={name}
                                    options={GenderOptions}
                                    {...{ name, label, rules, mask, disabled }}
                                />
                            );

                        case FormBlockLabel.birthDate:
                            return (
                                <CustomDataPicker
                                    key={name}
                                    {...{ name, form, label, rules, disabled }}
                                />
                            );

                        case FormBlockLabel.mobile:
                            return (
                                <CustomMaskInput
                                    key={name}
                                    {...{ name, label, rules, mask, disabled }}
                                />
                            );

                        default:
                            return (
                                <CustomMaskInput
                                    key={name}
                                    {...{ name, label, rules, mask, disabled, currentValue }}
                                />
                            );
                    }
                },
            )}
        </SectionContainer>
    );
};
