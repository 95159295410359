import { Typography } from 'antd';

import styles from './documents.module.css';

import { PRINT_DOCUMENTS_ACTION, PrintTemplates } from '~/constants/certificates';
import { CurrentPageTable } from '~/utils/transform-certificates-data/type';

type DocumentsProps = {
    variant: PrintTemplates;
    item: CurrentPageTable;
    printHandler: (templateId: PrintTemplates, data: CurrentPageTable) => void;
};

export const Documents = ({ variant, item, printHandler }: DocumentsProps) => (
    <Typography.Link onClick={() => printHandler(variant, item)} className={styles.certDocuments}>
        {PRINT_DOCUMENTS_ACTION[variant]}
    </Typography.Link>
);
