import { ValidationRules } from '../general';

import {
    AddressSectionKey,
    DocumentSectionKey,
    DocumentTypeLabel,
    FormSection,
    IdentityDocumentType,
    InputType,
    MainSectionKey,
    OtherSectionKey,
} from './certificate-form';

import {
    CommmonValidationSetup,
    DocumentInputSetup,
    DocumentValidationKey,
    RulesSetup,
} from '~/types/application-tab/certificate-form';
import { PartialRecord } from '~/types/common';

export const MaskPattern = {
    phone: '+7(000)0000000',
    phoneRegExp: '^7(\\d{10})$',
    cirylicSimbols: '^[-/ а-яА-Я]{1,}$',
    latinSimbols: '',
    latinCirylicSimbols: '',
    allSymbols: '',
    numbers1: '',
    numbersFour: '^[0-9]{4}$',
    numbersEleven: '000-000-000 00',
    numbersTwelwe: '^[0-9]{0,12}$',
    empty: '',
    emptyArr: ['', '', '', ''],
    series: ['00 00', '', '', ''],
    numbers: ['000000', '^[0-9a-zA-Z]{0,2}?[0-9]{0,7}$', '^[0-9]{1,9}$', '^[0-9]{1,9}$'],
    issueId: ['000 000', '', '', ''],
};

export const CommonSectionValidation: Record<
    Exclude<FormSection, 'document'>,
    PartialRecord<MainSectionKey | OtherSectionKey | AddressSectionKey, CommmonValidationSetup>
> = {
    main: {
        lastName: {
            label: 'Фамилия',
            type: InputType.text,
            mask: MaskPattern.cirylicSimbols,
            required: true,
        },
        firstName: {
            label: 'Имя',
            type: InputType.text,
            mask: MaskPattern.cirylicSimbols,
            required: true,
        },
        middleName: {
            label: 'Отчество',
            type: InputType.text,
            mask: MaskPattern.cirylicSimbols,
            required: true,
        },
        inn: {
            label: 'ИНН',
            type: InputType.text,
            mask: MaskPattern.numbersTwelwe,
            required: true,
        },
        snils: {
            label: 'СНИЛС',
            type: InputType.text,
            mask: MaskPattern.numbersEleven,
            required: true,
        },
        mobile: {
            label: 'Мобильный',
            type: InputType.tel,
            mask: MaskPattern.phone,
            regex: MaskPattern.phoneRegExp,
            required: true,
        },
        email: {
            label: 'Адрес E-mail',
            type: InputType.email,
        },
        gender: {
            label: 'Пол',
            type: InputType.select,
            required: true,
        },
    },

    address: {
        countryId: {
            label: 'Страна/регион',
            type: InputType.text,
        },
        region: {
            label: 'Область',
            type: InputType.text,
        },
        city: {
            label: 'Город',
            type: InputType.text,
        },
        addressString: {
            label: 'Адрес',
            type: InputType.text,
        },
    },

    other: {
        orgName: {
            label: 'Организация',
            type: InputType.text,
        },
        orgUnit: {
            label: 'Подразделение',
            type: InputType.text,
        },
        jobTitle: {
            label: 'Должность',
            type: InputType.text,
        },
    },
};

export const DocumentTypeValidation = {
    label: 'Тип документа',
    name: [FormSection.document, DocumentSectionKey.type],
    mask: MaskPattern.emptyArr,
    options: [
        {
            value: IdentityDocumentType.RfPassport,
            label: DocumentTypeLabel.RfPassport,
        },
        {
            value: IdentityDocumentType.FidDoc,
            label: DocumentTypeLabel.FidDoc,
        },
        {
            value: IdentityDocumentType.TempRes,
            label: DocumentTypeLabel.TempRes,
        },
        {
            value: IdentityDocumentType.ResPermit,
            label: DocumentTypeLabel.ResPermit,
        },
    ],
    rules: [ValidationRules.required],
};

export const DocumentSectionValidation: Record<
    IdentityDocumentType,
    Record<DocumentValidationKey, RulesSetup>
> = {
    [IdentityDocumentType.RfPassport]: {
        number: {
            required: true,
            regex: '^[0-9]{6}$',
        },
        series: {
            required: true,
            regex: '^[0-9]{2}(\\ )?[0-9]{2}$',
        },
        issueId: {
            required: true,
            regex: '^[0-9]{3}(\\ )?[0-9]{3}$',
        },
        issueDate: { required: true },
        issuedBy: { required: true },
        expiryDate: {},
    },

    [IdentityDocumentType.FidDoc]: {
        number: {
            required: true,
            regex: '^[0-9a-zA-Z]{0,2}?[0-9]{1,7}$',
        },
        series: {},
        issueId: { required: true },
        issueDate: { required: true },
        issuedBy: { required: true },
        expiryDate: {},
    },

    [IdentityDocumentType.TempRes]: {
        number: { required: true, regex: '^[0-9]{0,9}$' },
        series: { required: true },
        issueId: { required: true },
        issueDate: { required: true },
        issuedBy: { required: true },
        expiryDate: {},
    },

    [IdentityDocumentType.ResPermit]: {
        number: { required: true, regex: '^[0-9]{0,9}$' },
        series: { required: true },
        issueId: { required: true },
        issueDate: { required: true },
        issuedBy: { required: true },
        expiryDate: {},
    },

    [IdentityDocumentType.Empty]: {
        number: {},
        series: {},
        issueId: {},
        issueDate: {},
        issuedBy: {},
        expiryDate: {},
    },
};

export const DocumentInput: Record<DocumentValidationKey, DocumentInputSetup> = {
    series: {
        label: 'Серия',
        name: [FormSection.document, DocumentSectionKey.series],
        type: InputType.text,
        mask: MaskPattern.series,
    },
    number: {
        label: 'Номер',
        name: [FormSection.document, DocumentSectionKey.number],
        type: InputType.number,
        mask: MaskPattern.numbers,
    },
    issueDate: {
        label: 'Дата выдачи',
        name: [FormSection.document, DocumentSectionKey.issueDate],
        type: InputType.calendar,
        mask: MaskPattern.emptyArr,
    },
    issueId: {
        label: 'Код подразделения',
        name: [FormSection.document, DocumentSectionKey.issueId],
        type: InputType.text,
        mask: MaskPattern.issueId,
    },
    issuedBy: {
        label: 'Кем выдан',
        name: [FormSection.document, DocumentSectionKey.issuedBy],
        type: InputType.text,
        mask: MaskPattern.emptyArr,
    },
    expiryDate: {
        label: 'Действителен до',
        name: [FormSection.document, DocumentSectionKey.expiryDate],
        type: InputType.calendar,
        mask: MaskPattern.emptyArr,
    },
};

export const ResetDocumentName = (Object.values(DocumentInput) as { name: string[] }[]).map(
    ({ name }) => name,
);

export const RfPassportInput = [
    DocumentInput[DocumentSectionKey.series],
    DocumentInput[DocumentSectionKey.number],
    DocumentInput[DocumentSectionKey.issueDate],
    DocumentInput[DocumentSectionKey.issueId],
    DocumentInput[DocumentSectionKey.issuedBy],
];

export const FinDocInput = [
    DocumentInput[DocumentSectionKey.series],
    DocumentInput[DocumentSectionKey.number],
    DocumentInput[DocumentSectionKey.issuedBy],
    DocumentInput[DocumentSectionKey.issueDate],
    DocumentInput[DocumentSectionKey.expiryDate],
];

export const TempResInput = [
    DocumentInput[DocumentSectionKey.number],
    DocumentInput[DocumentSectionKey.issueDate],
    DocumentInput[DocumentSectionKey.expiryDate],
    DocumentInput[DocumentSectionKey.issuedBy],
];

export const ResPermitInput = [
    DocumentInput[DocumentSectionKey.number],
    DocumentInput[DocumentSectionKey.issueDate],
    DocumentInput[DocumentSectionKey.issuedBy],
    DocumentInput[DocumentSectionKey.expiryDate],
];

export const DocumentSectionInput: Record<IdentityDocumentType, DocumentInputSetup[]> = {
    [IdentityDocumentType.Empty]: [],
    [IdentityDocumentType.RfPassport]: RfPassportInput,
    [IdentityDocumentType.FidDoc]: FinDocInput,
    [IdentityDocumentType.TempRes]: TempResInput,
    [IdentityDocumentType.ResPermit]: ResPermitInput,
};
