import { defer, Params } from 'react-router-dom';

import { STEP_PARAMS } from '~/constants/routes';
import { stepName } from '~/constants/step-name';
import { store } from '~/store';
import { setIsLoading } from '~/store/common/common-slice';
import { checkCryptoInstalled } from '~/utils/plugin/check-crypto-installed';

export const loaderCryptoProPlugin = ({ params }: { params: Params<typeof STEP_PARAMS> }) => {
    const currentStep = params.stepName;

    console.log(currentStep);
    if (currentStep === stepName.one || currentStep === stepName.two) {
        console.log('ne tuda');

        return defer({ isPluginInstalled: Promise.resolve(true) });
    }

    const checkPlugin = async () => {
        console.log('checkPlugin');

        try {
            store.dispatch(setIsLoading(true));

            const cryptoProInstalled = await checkCryptoInstalled();

            console.log(Boolean(cryptoProInstalled));

            return Boolean(cryptoProInstalled);
        } catch (err) {
            console.error(err);
            store.dispatch(setIsLoading(false));

            return false;
        }
    };

    console.log('12');

    return defer({ isPluginInstalled: checkPlugin() });
};
