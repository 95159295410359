import { useSearchParams } from 'react-router-dom';
import { Table } from 'antd';
import { TablePaginationConfig, TableProps } from 'antd/es/table';
import { SorterResult } from 'antd/es/table/interface';

import { useGetFilters } from '~/components/certificates-table/hooks/use-get-filters';
import { DEFAULT_PAGE, DEFAULT_SIZE, SORT_ORDERS } from '~/constants/general';
import { CommonFilters, TableParams } from '~/types/common';
import { addColumnDefaultSortAndDefaultFilter } from '~/utils/add-column-default-sort-and-default-filter';
import { getPagination } from '~/utils/get-pagination';

export type CustomTableProps<T> = {
    currentTablePage: number | string;
    currentTableSize: number | string;
    currentSortColumn: string | null;
    currentSortOrder: string | null;
    totalCount: number;
    filterName: string;
    scrollX?: number | string | true;
    scrollY?: number | string;
} & TableProps<T>;
export const CustomTable = <T extends Record<string, unknown>>({
    columns = [],
    dataSource,
    currentTablePage,
    currentTableSize,
    currentSortColumn,
    currentSortOrder,
    totalCount,
    loading,
    filterName,
    scrollX = true,
    scrollY,
    ...restTableProps
}: CustomTableProps<T>) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const currentFilters = useGetFilters(filterName) as CommonFilters;

    const minCurrentTableSize =
        totalCount && Number(totalCount) < Number(currentTableSize) ? totalCount : currentTableSize;
    const updateSearchParams = (entries: Array<[name: string, value: string] | []>) => {
        entries.forEach(([name, value]) => {
            if (name && value) {
                searchParams.set(name, value);
            }
        });
        setSearchParams(searchParams);
    };

    const updateTableSort = (sorter: SorterResult<T>) => {
        const { order, field } = sorter as SorterResult<T>;

        if (order) {
            updateSearchParams([
                [TableParams.sortColumn, String(field)],
                [TableParams.sortOrder, SORT_ORDERS[order]],
            ]);
        } else {
            searchParams.delete(TableParams.sortColumn);
            searchParams.delete(TableParams.sortOrder);

            setSearchParams(searchParams);
        }
    };

    const handlePaginationChange = (page: number, pageSize: number) => {
        updateSearchParams([
            [TableParams.pageSize, String(pageSize)],
            [TableParams.pageNumber, String(page)],
        ]);
    };

    const tablePaginationConfig = getPagination(
        Number(currentTablePage),
        Number(minCurrentTableSize),
        totalCount,
        handlePaginationChange,
    );

    const handleChange = (
        _: TablePaginationConfig,
        filter: CommonFilters,
        sorter: SorterResult<T> | SorterResult<T>[],
    ) => {
        const filterMap = Object.entries(filter).filter(([, value]) => value);
        const filterObj = Object.fromEntries(filterMap);

        const keysPrevState = currentFilters && Object.keys(currentFilters);
        const keysFilterObj = filterObj && Object.keys(filterObj);
        const isPrevStateNotEmpty = keysPrevState?.length !== 0;
        const isFiltersNotChanged = keysPrevState?.every((key) => keysFilterObj?.includes(key));

        if (currentFilters && isPrevStateNotEmpty && !isFiltersNotChanged) {
            handlePaginationChange(DEFAULT_PAGE, DEFAULT_SIZE);
        }
        localStorage.setItem(filterName, JSON.stringify(filterObj));

        updateTableSort(sorter as SorterResult<T>);
    };

    return (
        <Table
            columns={addColumnDefaultSortAndDefaultFilter({
                columns,
                currentSortColumn,
                currentSortOrder,
                currentFilters,
            })}
            scroll={{ x: scrollX, y: scrollY }}
            dataSource={dataSource}
            pagination={tablePaginationConfig}
            onChange={handleChange}
            showSorterTooltip={false}
            loading={loading}
            {...restTableProps}
        />
    );
};
