export const checkCryptoInstalled = async (
    setCryptoInstalled?: React.Dispatch<React.SetStateAction<boolean>>,
) => {
    try {
        const { cadesplugin } = window;

        const ProviderName = 'Crypto-Pro GOST R 34.10-2012 Cryptographic Service Provider';
        const ProviderType = 80;

        const oAbout = await cadesplugin.CreateObjectAsync('CAdESCOM.About');
        const oVersion = await oAbout.CSPVersion(
            ProviderName,
            parseInt(ProviderType.toString(), 10),
        );

        const Version = oVersion.toString();

        if (setCryptoInstalled) setCryptoInstalled(true);
        console.log(Version);

        return Version;
    } catch (_) {
        if (setCryptoInstalled) setCryptoInstalled(false);

        return false;
        // Обработка возможных ошибок и возвращение null в случае их возникновения
    }
};
