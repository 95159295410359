import dayjs from 'dayjs';

import {
    DocumentSectionKey,
    IdentityDocumentType,
    InitialDocumentForm,
} from '~/constants/application-tab/certificate-form';
import { DATE_FORMAT } from '~/constants/general';
import { CreateDocument, CreateUserInfo } from '~/rtk-queries/types/certificates';
import { IdentityDocument, UserInfoDataValue } from '~/rtk-queries/types/user-info';
import { DocumentForm } from '~/types/application-tab/certificate-form';
import { PartialRecord } from '~/types/common';

type DocumentValueMap = { id?: number } & PartialRecord<
    Exclude<DocumentSectionKey, 'type' | 'id'>,
    string
>;

export const updateDocumentSection = (
    newType: IdentityDocumentType,
    draft: CreateUserInfo | null,
    data?: IdentityDocument[] | undefined,
) => {
    const currentDataDocument = data?.find(
        ({ type }) => type?.value && Number(type.value) === newType,
    );

    const dataDocumentValueMap: DocumentValueMap = {};

    if (currentDataDocument) {
        const { id, type: _type, ...restDocument } = currentDataDocument;

        dataDocumentValueMap.id = id;

        (Object.entries(restDocument) as [keyof typeof restDocument, UserInfoDataValue][]).forEach(
            ([key, { value }]) => {
                dataDocumentValueMap[key] = value;
            },
        );
    }

    const {
        expiryDate: dataExpiryDate,
        issueDate: dataIssueDate,

        ...restDataDocument
    } = dataDocumentValueMap;

    const {
        expiryDate: draftExpiryDate,
        issueDate: draftIssueDate,
        type: _type,
        ...restDraftDocument
    } = draft?.document?.type === newType ? draft.document : ({} as CreateDocument);

    const expiryDate = draftExpiryDate ?? dataExpiryDate;
    const issueDate = draftIssueDate ?? dataIssueDate;

    const updatedDocumentSection: DocumentForm = {
        ...InitialDocumentForm,
        ...restDataDocument,
        ...restDraftDocument,
    };

    if (expiryDate) {
        updatedDocumentSection.expiryDate = dayjs(expiryDate, DATE_FORMAT);
    }

    if (issueDate) {
        updatedDocumentSection.issueDate = dayjs(issueDate, DATE_FORMAT);
    }

    return updatedDocumentSection;
};
