import { useCallback, useLayoutEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Flex, Form, Space } from 'antd';
import { useForm } from 'antd/es/form/Form';
import Link from 'antd/es/typography/Link';

import styles from './application-tab.module.css';

import {
    AddressSection,
    DocumentsSection,
    MainSection,
    OtherSection,
    TemplatesSelect,
} from '~/components/application-tab';
import { TOOLTIP_TEXT_FOR_FORM } from '~/components/certificates-table/constants/general';
import { AccountTab } from '~/constants/account-tab';
import {
    FormSectionLabel,
    IntitialCertificateForm,
} from '~/constants/application-tab/certificate-form';
import {
    DRAFT_MODE,
    NotificationTextPartOne,
    NotificationTextPartTwo,
} from '~/constants/application-tab/general';
import { BUTTON_TEXT } from '~/constants/button';
import { LOCAL_STORAGE } from '~/constants/local-storage';
import { ROUTES } from '~/constants/routes';
import { WithLoader } from '~/hoc/with-loader';
import { useAppDispatch } from '~/hooks';
import { useCreateApplication } from '~/hooks/application-tab/use-create-application';
import { useDraft } from '~/hooks/application-tab/use-draft';
import { useGetTemplateData } from '~/hooks/application-tab/use-get-template-data';
import { useUpdateDraft } from '~/hooks/application-tab/use-update-draft';
import { useUserInfo } from '~/hooks/application-tab/use-user-info';
import { CustomButton } from '~/shared/custom-button';
import { FormTooltip } from '~/shared/form-components/form-tooltip';
import { TextLinkEnd } from '~/shared/text-link-end';
import { setOpenModalForMoveFromApplicationTab } from '~/store/app-tab/app-tab-slice';
import { CertificateForm } from '~/types/application-tab/certificate-form';
import { resetCertificateForm } from '~/utils/application-tab/reset-certificate-form';
import { getLocalStorageItem } from '~/utils/local-storage';

export const ApplicationTab = () => {
    const storedDraftData = getLocalStorageItem(LOCAL_STORAGE.draftData);

    const navigate = useNavigate();
    const location = useLocation();
    const isDraftMode = Boolean(location.state?.[DRAFT_MODE]);

    const dispatch = useAppDispatch();
    const [form] = useForm<CertificateForm>();
    const [isFormShowed, setIsFormShowed] = useState(false);

    const hideForm = () => setIsFormShowed(false);

    const { draft, isDraftInteractionInProgress, deleteDraft } = useDraft();

    const {
        templateSetup: { folderId, templateId },
        selectedTemplate,
        changeTemplate,
        resetTemplate,
        selectedTemplateName,
        isFetchingTemplate,
    } = useGetTemplateData();

    const { getUserInfo, userInfoData, isLoadingUserInfo } = useUserInfo(
        form,
        draft,
        isFormShowed,
        deleteDraft,
        hideForm,
    );

    const { updateDraft, isLoadingDraftCertificate } = useUpdateDraft(
        form,
        { folderId, templateId },
        userInfoData,
        selectedTemplateName,
    );

    const { createApplication, isLoadingCreateCertificate } = useCreateApplication(
        form,
        { folderId, templateId },
        userInfoData,
        deleteDraft,
        selectedTemplateName,
    );

    const isLoading =
        isFetchingTemplate ||
        isLoadingUserInfo ||
        isLoadingCreateCertificate ||
        isLoadingDraftCertificate ||
        isDraftInteractionInProgress;

    const formDisabled = isLoadingCreateCertificate || isLoadingDraftCertificate;

    const navigateForm = useCallback(() => {
        if (folderId && templateId) {
            getUserInfo({ folderId, templateId });
            dispatch(setOpenModalForMoveFromApplicationTab(true));

            setIsFormShowed(true);
        }
    }, [dispatch, folderId, getUserInfo, templateId]);

    const navigateBack = () => {
        resetCertificateForm(form);
        setIsFormShowed(false);
        resetTemplate();
        navigate(`${ROUTES.privateRoom}/${AccountTab.applicationTab}`);
    };

    useLayoutEffect(() => {
        if (storedDraftData) {
            navigateForm();
        }
    }, [navigateForm, storedDraftData]);

    if (isDraftMode || isFormShowed) {
        return (
            <WithLoader isLoading={isLoading} className={styles.spinner}>
                <Form
                    form={form}
                    className={styles.applicationTabForm}
                    initialValues={IntitialCertificateForm}
                    layout='vertical'
                >
                    <div className={styles.applicationTabBlocks}>
                        <FormTooltip title={TOOLTIP_TEXT_FOR_FORM} placement='topRight'>
                            <Button
                                type='default'
                                className={styles.backButton}
                                onClick={navigateBack}
                            >
                                <ArrowLeftOutlined className={styles.topArrowIcon} />
                                <span className={styles.applicationTitle}>
                                    {FormSectionLabel.data}
                                </span>
                            </Button>
                        </FormTooltip>

                        <Space className={styles.changeDataNotification}>
                            <TextLinkEnd screenTitle={NotificationTextPartOne} />
                            <span className={styles.notificationText}>
                                {NotificationTextPartTwo}
                            </span>
                        </Space>

                        <MainSection data={userInfoData} {...{ form }} />
                        <DocumentsSection
                            data={userInfoData?.userInfo.documents}
                            {...{ form, draft, formDisabled }}
                        />
                        <AddressSection data={userInfoData?.userInfo?.address} {...{ form }} />
                        <OtherSection data={userInfoData?.userInfo?.other} {...{ form }} />

                        <Flex wrap={true} vertical={true} className={styles.buttonsRow} gap={32}>
                            <FormTooltip title={TOOLTIP_TEXT_FOR_FORM} placement='topLeft'>
                                <Link className={styles.backButton} onClick={navigateBack}>
                                    <ArrowLeftOutlined className={styles.arrowIcon} />
                                    {BUTTON_TEXT.back}
                                </Link>
                            </FormTooltip>
                            <Space size={32}>
                                <CustomButton
                                    disabled={formDisabled}
                                    className='updatePageButton'
                                    onClick={updateDraft}
                                >
                                    {BUTTON_TEXT.saveDraft}
                                </CustomButton>

                                <CustomButton
                                    disabled={formDisabled}
                                    className='applicationTabButton'
                                    onClick={createApplication}
                                    type='primary'
                                >
                                    {BUTTON_TEXT.sendRequest}
                                </CustomButton>
                            </Space>
                        </Flex>
                    </div>
                </Form>
            </WithLoader>
        );
    }

    return (
        <TemplatesSelect onClickContinue={navigateForm} {...{ selectedTemplate, changeTemplate }} />
    );
};
