import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { BASE_PARAMS } from '~/components/certificates-table/constants/general';
import { ModalInfoType } from '~/components/view-answer-modal/modal-type';
import { AccountTab } from '~/constants/account-tab';
import { DRAFT_MODE } from '~/constants/application-tab/general';
import { ActionVariantsType } from '~/constants/certificates';
import { LifeStatus } from '~/constants/certificates-table';
import { LOCAL_STORAGE } from '~/constants/local-storage';
import { ERROR_TEXTS, ModalTextVariant, ModalType } from '~/constants/modal';
import { PluginError } from '~/constants/plugin';
import { ROUTES } from '~/constants/routes';
import { useAppDispatch } from '~/hooks';
import {
    useApplyRequestMutation,
    useLazyGetCertificatePKIDetailsQuery,
    useLazyGetDraftQuery,
    useRemoveDraftMutation,
    useRevokeCertificateMutation,
    useSendToArchiveMutation,
} from '~/rtk-queries/endpoints/certificates-list';
import { useLazyGetTemplateQuery } from '~/rtk-queries/endpoints/template';
import { PKIDetailsResponse } from '~/rtk-queries/types/certificates';
import { TemplatesResponse } from '~/rtk-queries/types/template';
import { setPopupAlert } from '~/store/auth/auth-slice';
import { setDraftData } from '~/store/draft/get-draft-slice';
import { createAlertError } from '~/utils/create-alert-error';
import { downloadCertificate } from '~/utils/download-certificate';
import { getRequestData } from '~/utils/get-request-data';
import { isCertificateData, isPreApplicationData } from '~/utils/is-application-data-type';
import { setLocalStorageItem } from '~/utils/local-storage';
import { checkCryptoInstalled } from '~/utils/plugin/check-crypto-installed';
import { generateKeysUtil } from '~/utils/plugin/generate-keys-util';
import { installCertificateUtil } from '~/utils/plugin/install-certificate-util';
import { revokeCertificateUtils } from '~/utils/plugin/revoke-certificate-util';
import { titleCertificate } from '~/utils/title-certificate';
import { CurrentPageTable } from '~/utils/transform-certificates-data/type';

const initialModalInfo: ModalInfoType = {
    text: '',
    isError: false,
    type: ModalType.viewAnswer,
    title: '',
};

export const useCertificateAction = (reason: number, currentCertificate: CurrentPageTable) => {
    const dispatch = useAppDispatch();
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [modalInfo, setModalInfo] = useState(initialModalInfo);
    const [isRevokeRequest, setIsRevokeRequest] = useState(false);

    const [
        revokeCertificate,
        {
            isSuccess: isSuccessRevokeCertificate,
            isLoading: isLoadingRevoke,
            reset: resetRevokeCertificate,
        },
    ] = useRevokeCertificateMutation();
    const [getTemplateData, { isFetching: isFetchingTemplate }] = useLazyGetTemplateQuery();
    const [getPKIDetails, { isFetching: isFetchingPKIDetails }] =
        useLazyGetCertificatePKIDetailsQuery();
    const [getDraft, { isFetching: isFetchingDraft }] = useLazyGetDraftQuery();
    const [
        removeDraft,
        {
            isSuccess: isSuccessRemoveDraft,
            isLoading: isLoadingLoadingRemoveDraft,
            reset: resetRemoveDraft,
        },
    ] = useRemoveDraftMutation();
    const [
        applyRequest,
        {
            isSuccess: isSuccessApplyRequest,
            isLoading: isLoadingApplyRequest,
            reset: resetApplyRequest,
        },
    ] = useApplyRequestMutation();

    const [
        sendToArchiveRequest,
        {
            isSuccess: isSuccessSendToArchive,
            isLoading: isLoadingSendToArchive,
            reset: resetSendToArchive,
        },
    ] = useSendToArchiveMutation();

    const navigate = useNavigate();
    const isFetchingDetails =
        isLoadingRevoke ||
        isFetchingPKIDetails ||
        isFetchingTemplate ||
        isLoadingApplyRequest ||
        isLoadingSendToArchive ||
        isFetchingDraft ||
        isLoadingLoadingRemoveDraft;

    const checkExtension = async () => {
        const isExtensionWork = await checkCryptoInstalled();

        if (!isExtensionWork) {
            setModalInfo({
                text: ModalTextVariant.needExtension,
                isError: true,
                type: ModalType.viewAnswer,
                title: ModalTextVariant.needExtensionTitle,
            });
            setIsOpenModal(true);
        }

        return isExtensionWork;
    };

    const openGenerateKeysModal = async () => {
        const isExtensionRun = await checkExtension();

        if (!isExtensionRun) return;
        setModalInfo({
            text: ModalTextVariant.generateKeys,
            isError: false,
            type: ModalType.generateKeys,
            title: '',
        });
        setIsOpenModal(true);
    };

    const approveRequest = useCallback(
        async (data?: TemplatesResponse) => {
            if (!data || currentCertificate.lifeStatus !== LifeStatus.preApplication) return;
            const rawRequest = await generateKeysUtil(
                data.templates[0],
                currentCertificate.distinguishedName,
            );

            if (rawRequest.isError) {
                if (rawRequest.title === ERROR_TEXTS[PluginError.cancel]) {
                    dispatch(setPopupAlert(createAlertError(rawRequest.text).alert));
                } else {
                    setModalInfo({
                        text: rawRequest.text,
                        isError: rawRequest.isError,
                        type: ModalType.viewAnswer,
                        title: rawRequest.title,
                    });

                    setIsOpenModal(true);
                }
            } else {
                applyRequest({
                    rawRequest: rawRequest.text,
                    requestId: currentCertificate.lkRequestId,
                });
            }
        },
        [applyRequest, currentCertificate, dispatch],
    );

    const generateKeys = async () => {
        const isExtensionRun = await checkExtension();

        if (!isExtensionRun || currentCertificate.lifeStatus !== LifeStatus.preApplication) return;
        setIsOpenModal(false);
        const { data } = await getTemplateData({ id: currentCertificate.templateId });

        approveRequest(data);
    };

    const openRevokeModal = async () => {
        const isExtensionRun = await checkExtension();

        if (!isExtensionRun) return;
        setModalInfo({
            text: '',
            isError: false,
            type: ModalType.revoke,
            title: '',
        });
        setIsOpenModal(true);
    };

    const revokeRequest = async () => {
        setIsOpenModal(false);

        if (currentCertificate.lifeStatus !== LifeStatus.certificate) return;
        try {
            if (isCertificateData(currentCertificate)) {
                const rawRequest = await revokeCertificateUtils(
                    currentCertificate.thumbprint,
                    reason,
                );

                if (rawRequest.isError) {
                    if (rawRequest.title === ERROR_TEXTS[PluginError.cancel]) {
                        dispatch(setPopupAlert(createAlertError(rawRequest.text).alert));
                    } else {
                        setModalInfo({
                            text: rawRequest.text,
                            isError: rawRequest.isError,
                            type: ModalType.viewAnswer,
                            title: rawRequest.title,
                        });
                        setIsOpenModal(true);
                    }
                } else {
                    await revokeCertificate({ rawRequest: rawRequest.text });
                    setIsRevokeRequest(true);
                }
            }
        } catch (e) {
            console.error(e);
        }
    };

    const installCertificate = useCallback(
        async (data?: PKIDetailsResponse) => {
            const isExtensionRun = await checkExtension();

            if (!isExtensionRun || !data) return;
            const installData = await installCertificateUtil(
                data.certificateDetails.rawCertificate,
            );

            if (installData.title === ERROR_TEXTS[PluginError.cancel]) {
                dispatch(setPopupAlert(createAlertError(installData.text).alert));
            } else {
                setModalInfo({
                    text: installData.text,
                    isError: installData.isError,
                    type: ModalType.viewAnswer,
                    title: installData.title,
                });

                setIsOpenModal(true);
            }
        },
        [dispatch],
    );

    const getDetailsForInstall = async (data: CurrentPageTable) => {
        if (data.lifeStatus !== LifeStatus.certificate) return;
        if (isCertificateData(data)) {
            const { data: pkiData } = await getPKIDetails({
                certificateId: data?.certificateId,
            });

            installCertificate(pkiData);
        }
    };

    const openAnswerModal = () => {
        setModalInfo({
            isError: false,
            type: ModalType.viewAnswer,
            title: ModalTextVariant.answer,
        });
        setIsOpenModal(true);
    };

    const continueDraft = async (data: CurrentPageTable) => {
        if (isPreApplicationData(data)) {
            const RequestId = data.lkRequestId;
            const { data: draftData } = await getDraft({ RequestId });

            if (draftData) {
                const draftInfo = {
                    RequestId,
                    folderId: draftData.folderId,
                    templateId: draftData.templateId,
                };

                setLocalStorageItem(LOCAL_STORAGE.draftData, draftInfo);
                dispatch(setDraftData(draftData));
                navigate(`${ROUTES.privateRoom}/${AccountTab.applicationTab}`, {
                    state: { [DRAFT_MODE]: true },
                });
            }
        }
    };

    const deleteDraft = async (data: CurrentPageTable) => {
        if (isPreApplicationData(data)) {
            const RequestId = data.lkRequestId;

            const { data: removeData } = await removeDraft({ RequestId });

            if (removeData) {
                setModalInfo({
                    text: removeData.hasError
                        ? ModalTextVariant.tryAgain
                        : ModalTextVariant.removeDraft,
                    isError: removeData.hasError || false,
                    type: ModalType.viewAnswer,
                    title: removeData.hasError ? ModalTextVariant.error : '',
                });
                setIsOpenModal(true);
            }
        }
    };

    const sendToArchive = (data: CurrentPageTable) => {
        const requestData = getRequestData(data);

        sendToArchiveRequest(requestData);
    };

    const saveToFile = async (data: CurrentPageTable) => {
        if (data.lifeStatus !== LifeStatus.certificate) return;
        if (isCertificateData(data)) {
            const { data: pkiData, isSuccess } = await getPKIDetails({
                certificateId: data?.certificateId,
            });

            if (isSuccess) {
                downloadCertificate({
                    fileName: titleCertificate(data.title),
                    base64File: pkiData.certificateDetails.rawCertificate,
                });
            }
        }
    };

    const actionHandler = {
        [ActionVariantsType.continueDraft]: continueDraft,
        [ActionVariantsType.deleteDraft]: deleteDraft,
        [ActionVariantsType.installCertificate]: (data: CurrentPageTable) =>
            getDetailsForInstall(data),
        [ActionVariantsType.keyGeneration]: openGenerateKeysModal,
        [ActionVariantsType.revokeCertificate]: openRevokeModal,
        [ActionVariantsType.viewAnswer]: openAnswerModal,
        [ActionVariantsType.sendToArchive]: (data: CurrentPageTable) => sendToArchive(data),
        [ActionVariantsType.repeatChecks]: () => {},
        [ActionVariantsType.saveToFile]: (data: CurrentPageTable) => saveToFile(data),
    };

    useEffect(() => {
        if (isSuccessApplyRequest || isSuccessRevokeCertificate || isSuccessRemoveDraft) {
            localStorage.removeItem(LOCAL_STORAGE.filtersForCertificates);
            navigate(`${ROUTES.privateRoom}/${AccountTab.certificateTab}${BASE_PARAMS}`);
            resetRevokeCertificate();
            resetApplyRequest();
            resetRemoveDraft();
        }
        if (isSuccessSendToArchive) {
            resetSendToArchive();
        }
    }, [
        isSuccessApplyRequest,
        isSuccessRevokeCertificate,
        isSuccessSendToArchive,
        navigate,
        resetApplyRequest,
        resetRevokeCertificate,
        resetSendToArchive,
        resetRemoveDraft,
        isSuccessRemoveDraft,
    ]);

    return {
        actionHandler,
        isOpenModal,
        revokeRequest,
        generateKeys,
        setIsOpenModal,
        modalInfo,
        isFetchingDetails,
        isRevokeRequest,
        setIsRevokeRequest,
    };
};
