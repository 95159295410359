import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/query';
import { Empty } from 'antd';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';

import { useGetFilters } from '../certificates-table/hooks/use-get-filters';

import { returnColumnsForArchiveTable } from './constants/return-columns-for-archive-table';

import styles from './archive.module.css';

import { AccountTab } from '~/constants/account-tab';
import { LOCAL_STORAGE } from '~/constants/local-storage';
import { startObj, useHookForPagination } from '~/hooks/use-hook-for-pagination';
import { usePreventScroll } from '~/hooks/use-prevent-scroll';
import {
    useGetCertificatesListQuery,
    useUnarchiveMutation,
} from '~/rtk-queries/endpoints/certificates-list';
import { CertificatesTableResponse } from '~/rtk-queries/types/certificates';
import { CustomTable } from '~/shared/custom-table/custom-table';
import { TableFilters } from '~/types/common';

dayjs.extend(buddhistEra);

export const Archive = () => {
    const { tabName } = useParams();
    const filtersForArchives = useGetFilters(LOCAL_STORAGE.filtersForArchives);
    const {
        searchParamsObj,
        isEmptyParamObj,
        currentTableSize,
        currentTablePage,
        currentSortOrder,
        currentSortColumn,
    } = useHookForPagination();

    const skip = tabName !== AccountTab.archiveTab;

    const bodyForCertificateList = useMemo(() => {
        const { lastEditDate, ...rest } = filtersForArchives as TableFilters;
        const isArchive = true;

        const newFilter = {
            ...rest,
            isArchive,
            ...(lastEditDate?.[0] && { lastEditDateFrom: lastEditDate[0] }),
            ...(lastEditDate?.[1] && { lastEditDateTo: lastEditDate[1] }),
        };

        return { ...newFilter, ...(isEmptyParamObj ? startObj : searchParamsObj) };
    }, [filtersForArchives, isEmptyParamObj, searchParamsObj]);

    const { data: certificatesTableData = {} as CertificatesTableResponse, isFetching } =
        useGetCertificatesListQuery(skip ? skipToken : bodyForCertificateList);
    const [unarchiveRequest] = useUnarchiveMutation();

    const { totalCount, currentPage = [], allTitles = [] } = certificatesTableData;

    const columns = returnColumnsForArchiveTable({
        allTitles,
        unarchiveRequest,
    });

    usePreventScroll(isFetching);

    return (
        <CustomTable
            className={styles.archiveTable}
            dataSource={currentPage}
            columns={columns}
            currentTablePage={currentTablePage}
            currentTableSize={currentTableSize}
            currentSortColumn={currentSortColumn}
            currentSortOrder={currentSortOrder}
            totalCount={totalCount}
            loading={{ spinning: isFetching }}
            locale={{
                emptyText: isFetching ? (
                    <span style={{ display: 'inline-block', height: 100 }} />
                ) : (
                    <Empty description='Нет данных' />
                ),
            }}
            filterName={LOCAL_STORAGE.filtersForArchives}
            scrollX={900}
        />
    );
};
