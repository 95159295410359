import { DownloadOutlined } from '@ant-design/icons';

export enum TagType {
    draft = 'Черновик',
    sentCheck = 'Ожидает проверки сведений',
    foldingChecks = 'Выполняется проверка сведений',
    errorChecksData = 'Неуспешная проверка сведений',
    checksExecute = 'Успешная проверка сведений',
    verificationIsOverdue = 'Проверка сведений просрочена',
    sendToArchive = 'Отправить запрос в архив',
    operatorConfirmation = 'Необходим визит в Банк',
    applicationRejected = 'Запрос на выпуск сертификата отклонен',
    applicationFulfilled = 'Ожидание выпуска сертификата',
    released = 'Сертификат выпущен',
    revoked = 'Сертификат отозван',
    operatorConfirmationForRevoke = 'Запрос на отзыв рассматривается Банком',
    applicationForReviewRejected = 'Запрос на отзыв отклонён',
}

const ActionVariantsTitle = {
    continueDraft: 'Открыть черновик',
    deleteDraft: 'Удалить',
    keyGeneration: 'Сгенерировать ключи',
    installCertificate: 'Установить сертификат',
    revokeCertificate: 'Отозвать сертификат',
    viewAnswer: 'Результаты проверок',
    sendToArchive: 'Отправить в архив',
    repeatChecks: 'Запустить проверки',
    saveToFile: <DownloadOutlined />,
} as const;

type ActionVariantsTitleType = keyof typeof ActionVariantsTitle;

export enum ActionVariantsType {
    continueDraft = 'continueDraft',
    deleteDraft = 'deleteDraft',
    keyGeneration = 'keyGeneration',
    installCertificate = 'installCertificate',
    revokeCertificate = 'revokeCertificate',
    viewAnswer = 'viewAnswer',
    sendToArchive = 'sendToArchive',
    repeatChecks = 'repeatChecks',
    saveToFile = 'saveToFile',
}

export enum StatusCode {
    Draft = 1,
    SentForCheck = 2,
    MadeCheck = 3,
    ValidateError = 4,
    CheckComplited = 5,
    AwaitingOperatorConfirmation = 6,
    ApplicationRejected = 7,
    ApplicationCompleted = 8,
    Released = 9,
    Revoked = 10,
    OperatorConfirmationForRevoke = 11,
    ApplicationForReviewRejected = 12,
    VerificationIsOverdue = 14,
}

export const statusesRevokeInProgress = [
    StatusCode.OperatorConfirmationForRevoke,
    StatusCode.ApplicationForReviewRejected,
];

enum TagColor {
    draft = '#ade4f980',
    waiting = '#ffebad80',
    success = '#c1ffc180',
    error = '#ffa6a680',
}

enum TagBorderColor {
    draft = '#ade4f9',
    waiting = '#ffebad',
    success = '#c1ffc1',
    error = '#ffa6a6',
}

const TAG_STYLE = {
    draft: { bgColor: TagColor.draft, borderColor: TagBorderColor.draft },
    waiting: { bgColor: TagColor.waiting, borderColor: TagBorderColor.waiting },
    success: { bgColor: TagColor.success, borderColor: TagBorderColor.success },
    error: { bgColor: TagColor.error, borderColor: TagBorderColor.error },
};

export enum PrintTemplates {
    Certificate = 0,
    CertRequest = 1,
    RevokeRequest = 2,
    User = 3,
    Header = 4,
}

enum DocumentsVariant {
    User = 'Регистрация пользователя',
    CertRequest = 'Изготовление сертификата',
    Certificate = 'Данные сертификата',
    RevokeRequest = 'Отзыв сертификата',
}

export const PRINT_DOCUMENTS_ACTION = {
    [PrintTemplates.User]: DocumentsVariant.User,
    [PrintTemplates.CertRequest]: DocumentsVariant.CertRequest,
    [PrintTemplates.Certificate]: DocumentsVariant.Certificate,
    [PrintTemplates.RevokeRequest]: DocumentsVariant.RevokeRequest,
    [PrintTemplates.Header]: DocumentsVariant.Certificate,
};

export type TagColorType = {
    bgColor: TagColor;
    borderColor: TagBorderColor;
};

type CertificateInfoType = Record<
    StatusCode,
    {
        titleStatus: TagType;
        color: TagColorType;
        actionsVariant: ActionVariantsType[];
        documents: PrintTemplates[];
    }
>;

export const CERTIFICATES_STATUS_INFO: CertificateInfoType = {
    [StatusCode.Draft]: {
        titleStatus: TagType.draft,
        color: TAG_STYLE.draft,
        actionsVariant: [ActionVariantsType.continueDraft, ActionVariantsType.deleteDraft],
        documents: [],
    },
    [StatusCode.SentForCheck]: {
        titleStatus: TagType.sentCheck,
        color: TAG_STYLE.waiting,
        actionsVariant: [ActionVariantsType.sendToArchive],
        documents: [PrintTemplates.User],
    },
    [StatusCode.MadeCheck]: {
        titleStatus: TagType.foldingChecks,
        color: TAG_STYLE.waiting,
        actionsVariant: [ActionVariantsType.sendToArchive],
        documents: [PrintTemplates.User],
    },
    [StatusCode.ValidateError]: {
        titleStatus: TagType.errorChecksData,
        color: TAG_STYLE.error,
        actionsVariant: [ActionVariantsType.sendToArchive],
        documents: [PrintTemplates.User],
    },
    [StatusCode.CheckComplited]: {
        titleStatus: TagType.checksExecute,
        color: TAG_STYLE.success,
        actionsVariant: [ActionVariantsType.keyGeneration, ActionVariantsType.sendToArchive],
        documents: [PrintTemplates.User],
    },
    [StatusCode.AwaitingOperatorConfirmation]: {
        titleStatus: TagType.operatorConfirmation,
        color: TAG_STYLE.success,
        actionsVariant: [ActionVariantsType.sendToArchive],
        documents: [PrintTemplates.CertRequest],
    },
    [StatusCode.ApplicationRejected]: {
        titleStatus: TagType.applicationRejected,
        color: TAG_STYLE.error,
        actionsVariant: [ActionVariantsType.sendToArchive],
        documents: [PrintTemplates.CertRequest],
    },
    [StatusCode.ApplicationCompleted]: {
        titleStatus: TagType.applicationFulfilled,
        color: TAG_STYLE.waiting,
        actionsVariant: [ActionVariantsType.sendToArchive],
        documents: [PrintTemplates.CertRequest],
    },
    [StatusCode.Released]: {
        titleStatus: TagType.released,
        color: TAG_STYLE.success,
        actionsVariant: [
            ActionVariantsType.installCertificate,
            ActionVariantsType.revokeCertificate,
            ActionVariantsType.saveToFile,
        ],
        documents: [PrintTemplates.Certificate],
    },
    [StatusCode.Revoked]: {
        titleStatus: TagType.revoked,
        color: TAG_STYLE.error,
        actionsVariant: [ActionVariantsType.sendToArchive],
        documents: [PrintTemplates.Certificate, PrintTemplates.RevokeRequest],
    },
    [StatusCode.OperatorConfirmationForRevoke]: {
        titleStatus: TagType.operatorConfirmationForRevoke,
        color: TAG_STYLE.waiting,
        actionsVariant: [ActionVariantsType.sendToArchive],
        documents: [PrintTemplates.Certificate, PrintTemplates.RevokeRequest],
    },
    [StatusCode.ApplicationForReviewRejected]: {
        titleStatus: TagType.applicationForReviewRejected,
        color: TAG_STYLE.error,
        actionsVariant: [ActionVariantsType.sendToArchive],
        documents: [PrintTemplates.Certificate, PrintTemplates.RevokeRequest],
    },
    [StatusCode.VerificationIsOverdue]: {
        titleStatus: TagType.verificationIsOverdue,
        color: TAG_STYLE.error,
        actionsVariant: [ActionVariantsType.sendToArchive],
        documents: [PrintTemplates.User],
    },
};

export const CERTIFICATES_ACTIONS: Record<
    ActionVariantsType,
    (typeof ActionVariantsTitle)[ActionVariantsTitleType]
> = {
    [ActionVariantsType.continueDraft]: ActionVariantsTitle.continueDraft,
    [ActionVariantsType.deleteDraft]: ActionVariantsTitle.deleteDraft,
    [ActionVariantsType.keyGeneration]: ActionVariantsTitle.keyGeneration,
    [ActionVariantsType.installCertificate]: ActionVariantsTitle.installCertificate,
    [ActionVariantsType.revokeCertificate]: ActionVariantsTitle.revokeCertificate,
    [ActionVariantsType.viewAnswer]: ActionVariantsTitle.viewAnswer,
    [ActionVariantsType.sendToArchive]: ActionVariantsTitle.sendToArchive,
    [ActionVariantsType.repeatChecks]: ActionVariantsTitle.repeatChecks,
    [ActionVariantsType.saveToFile]: ActionVariantsTitle.saveToFile,
};
