import dayjs from 'dayjs';

import { addRussianPhoneNumber } from './add-russian-phone-number';

import { DATE_FORMAT } from '~/constants/general';
import { CreateUserInfo } from '~/rtk-queries/types/certificates';
import { UserInfo, UserInfoDataValue } from '~/rtk-queries/types/user-info';
import { CertificateForm } from '~/types/application-tab/certificate-form';

export const getInitialCommonSection = (draft: CreateUserInfo | null, data?: UserInfo) => {
    let initialCommonSection: Partial<Omit<CertificateForm, 'document'>> = {};

    if (!data) return initialCommonSection;

    const { birthDate: dataBirthDate, ...restMainInfo } = data.main;

    const flatUserInfo = {
        ...restMainInfo,
        ...data.address,
        ...data.other,
    };

    (Object.entries(flatUserInfo) as [keyof typeof flatUserInfo, UserInfoDataValue][]).forEach(
        ([key, { value }]) => {
            initialCommonSection[key] = value;
        },
    );

    initialCommonSection.birthDate = dataBirthDate?.value
        ? dayjs(dataBirthDate?.value, DATE_FORMAT)
        : undefined;

    if (!draft) return initialCommonSection;

    const {
        birthDate: initialBirthDate,
        citizenshipId: initalCitizenshipId,
        countryId: initialCountryId,
        gender: initialGender,
        mobile: initalMobile,
    } = initialCommonSection;

    const {
        id: _id,
        document: _document,
        gender: draftGender,
        birthDate: draftBirthDate,
        citizenshipId: draftCitizenshipId,
        mobile: draftMobile,
        countryId: draftCountryId,
        ...restDraft
    } = draft;

    const citizenshipId = draftCitizenshipId ? String(draftCitizenshipId) : initalCitizenshipId;
    const countryId = draftCountryId ? String(draftCountryId) : initialCountryId;
    const gender = draftGender ? String(draftGender) : initialGender;
    const birthDate = draftBirthDate ? dayjs(draftBirthDate) : initialBirthDate;
    const mobile = draftMobile ? addRussianPhoneNumber(draftMobile) : initalMobile;

    initialCommonSection = {
        ...initialCommonSection,
        birthDate,
        citizenshipId,
        countryId,
        gender,
        mobile,
        ...restDraft,
    };

    return initialCommonSection;
};
