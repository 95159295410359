import { CertificateForm } from '~/types/application-tab/certificate-form';

export enum InputType {
    text = 'text',
    number = 'number',
    email = 'email',
    select = 'select',
    tel = 'tel',
    calendar = 'calendar',
}

export enum FormSection {
    main = 'main',
    address = 'address',
    other = 'other',
    document = 'document',
}

export enum MainSectionKey {
    firstName = 'firstName',
    lastName = 'lastName',
    middleName = 'middleName',
    birthDate = 'birthDate',
    gender = 'gender',
    citizenshipId = 'citizenshipId',
    inn = 'inn',
    snils = 'snils',
    mobile = 'mobile',
    email = 'email',
}

export const MainSectionOrder = [
    MainSectionKey.lastName,
    MainSectionKey.firstName,
    MainSectionKey.middleName,
    MainSectionKey.snils,
    MainSectionKey.inn,
    MainSectionKey.citizenshipId,
    MainSectionKey.birthDate,
    MainSectionKey.gender,
    MainSectionKey.mobile,
    MainSectionKey.email,
];

export enum DocumentSectionKey {
    id = 'id',
    type = 'type',
    series = 'series',
    number = 'number',
    issueDate = 'issueDate',
    issueId = 'issueId',
    issuedBy = 'issuedBy',
    expiryDate = 'expiryDate',
}

export enum AddressSectionKey {
    addressString = 'addressString',
    city = 'city',
    countryId = 'countryId',
    region = 'region',
}

export enum OtherSectionKey {
    orgName = 'orgName',
    orgUnit = 'orgUnit',
    jobTitle = 'jobTitle',
}

export enum IdentityDocumentType {
    Empty = 0,
    RfPassport = 1,
    FidDoc = 2,
    TempRes = 3,
    ResPermit = 4,
}

export const DOCUMENT_NUMBER_LABEL = 'Номер решения';

export const DocumentTypeLabel = {
    Empty: '',
    RfPassport: 'Паспорт РФ',
    FidDoc: 'Документ иностранного гражданина',
    TempRes: 'Разрешение на временное проживание',
    ResPermit: 'Вид на жительство',
};

export enum Gender {
    unknown = '0',
    male = '1',
    female = '2',
}

export const GenderLabel = {
    unknown: '',
    male: 'Мужской',
    female: 'Женский',
};

export const GenderOptions = [
    {
        value: Gender.male,
        label: GenderLabel.male,
    },
    {
        value: Gender.female,
        label: GenderLabel.female,
    },
];

export const FormSectionLabel = {
    data: 'Данные сертификата',
    main: 'Основные данные',
    documents: 'Документ, удостоверяющий личность',
    address: 'Адрес места жительства',
    other: 'Дополнительные данные',
};

export const InitialDocumentForm: CertificateForm['document'] = {
    id: 0,
    number: '',
    series: '',
    issuedBy: '',
    issueId: '',
    expiryDate: null,
    issueDate: null,
};

export const IntitialCertificateForm: CertificateForm = {
    firstName: '',
    lastName: '',
    middleName: '',
    birthDate: null,
    email: '',
    gender: '',
    inn: '',
    snils: '',
    mobile: '',
    addressString: '',
    citizenshipId: '',
    countryId: '',
    region: '',
    city: '',
    jobTitle: '',
    orgName: '',
    orgUnit: '',
    document: InitialDocumentForm,
};
